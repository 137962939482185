<template>
  <FormModal show-back-btn :title="headTitle">
    <template #body>
      <AmountFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AmountFormStep from '~/components/form/steps/amount/AmountFormStep'

export default {
  components: {
    AmountFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: '',
      headDescription: '',
      path: '/offertes-aanvragen/aantal',
      progressValue: 33,
      checkoutStep: 3,
    }
  },

  /**
   *
   */
  beforeMount() {
    const service =
      this.$store.getters['lead/getData']('service')?.[0]?.id || 'zonwering(en)'

    const title = `Om hoeveel ${service.toLowerCase()} gaat het?`

    this.headDescription = this.headTitle = title
  },
}
</script>

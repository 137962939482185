<template>
  <ExecutionPeriodRadioField
    :default-choice-selected="false"
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import ExecutionPeriodRadioField from 'chimera/all/components/form/fields/executionPeriod/ExecutionPeriodRadioField.vue'

export default {
  name: 'ExecutionPeriodFormPart',

  components: {
    ExecutionPeriodRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          this.$i18n.t('field.execution-period.options.within-1-month'),
          this.$i18n.t('field.execution-period.options.within-1-month'),
          this.$i18n.t('field.execution-period.options.within-1-month'),
        ),
        new Selectable(
          this.$i18n.t('field.execution-period.options.1-to-3-months'),
          this.$i18n.t('field.execution-period.options.1-to-3-months'),
          this.$i18n.t('field.execution-period.options.1-to-3-months'),
        ),
        new Selectable(
          this.$i18n.t('field.execution-period.options.3-to-6-months'),
          this.$i18n.t('field.execution-period.options.3-to-6-months'),
          this.$i18n.t('field.execution-period.options.3-to-6-months'),
        ),
        new Selectable(
          this.$i18n.t('field.execution-period.options.in-consultation'),
          this.$i18n.t('field.execution-period.options.in-consultation'),
          this.$i18n.t('field.execution-period.options.in-consultation'),
        ),
      ],
    }
  },
}
</script>

<template>
  <FloorField :choices="choices" v-on="$listeners" />
</template>

<script>
import FloorField from 'chimera/all/components/form/fields/floor/FloorField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'FloorFormPart',

  components: {
    FloorField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          this.$i18n.t('field.floor.options.groundFloor'),
          this.$i18n.t('field.floor.options.groundFloor'),
          this.$i18n.t('field.floor.options.groundFloor'),
        ),
        new Selectable(
          this.$i18n.t('field.floor.options.firstFloor'),
          this.$i18n.t('field.floor.options.firstFloor'),
          this.$i18n.t('field.floor.options.firstFloor'),
        ),
        new Selectable(
          this.$i18n.t('field.floor.options.secondFloor'),
          this.$i18n.t('field.floor.options.secondFloor'),
          this.$i18n.t('field.floor.options.secondFloor'),
        ),
        new Selectable(
          this.$i18n.t('field.floor.options.higherThanSecondFloor'),
          this.$i18n.t('field.floor.options.higherThanSecondFloor'),
          this.$i18n.t('field.floor.options.higherThanSecondFloor'),
        ),
      ],
    }
  },
}
</script>

<template>
  <FormModal :title="headTitle" show-back-btn>
    <template #body>
      <FloorFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import FloorFormStep from '~/components/form/steps/floor/FloorFormStep.vue'

export default {
  components: {
    FloorFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: '',
      headDescription: '',
      path: '/offertes-aanvragen/verdieping',
      progressValue: 55,
      checkoutStep: 5,
    }
  },

  /**
   *
   */
  beforeMount() {
    const service =
      this.$store.getters['lead/getData']('service')?.[0]?.id || 'zonwering(en)'

    const title = `Op welke verdieping moeten de ${service.toLowerCase()} geplaatst worden?`

    this.headDescription = this.headTitle = title
  },
}
</script>

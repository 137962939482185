<template>
  <FormModal show-back-btn :title="headTitle">
    <template #body>
      <ControlMethodFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import ControlMethodFormStep from '~/components/form/steps/controlMethod/ControlMethodFormStep.vue'

export default {
  components: {
    ControlMethodFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: '',
      headDescription: '',
      path: '/offertes-aanvragen/bediening',
      progressValue: 44,
      checkoutStep: 4,
    }
  },

  /**
   *
   */
  beforeMount() {
    const service =
      this.$store.getters['lead/getData']('service')?.[0]?.id || 'zonwering(en)'

    const title = `Hoe wil je de ${service.toLowerCase()} bedienen?`

    this.headDescription = this.headTitle = title
  },
}
</script>

<template>
  <div class="form-row">
    <CommentsField
      :is-optional="true"
      placeholder="Hier kun je eventuele bijzonderheden of specifieke vragen / wensen doorgeven"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import CommentsField from 'chimera/all/components/form/fields/comments/CommentsField.vue'

export default {
  name: 'CommentsFormPart',

  components: {
    CommentsField,
  },
}
</script>

<style lang="scss" scoped>
::v-deep .t-textarea.field-comments {
  textarea {
    min-height: 10rem;
  }
}
</style>

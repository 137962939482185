<template>
  <TypeOfWorkField
    :choices="choices"
    :multiple="false"
    :default-choice-selected="false"
    v-on="$listeners"
  />
</template>

<script>
import TypeOfWorkField from 'chimera/all/components/form/fields/typeOfWork/TypeOfWorkField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'TypeOfWorkRadioFormPart',

  components: {
    TypeOfWorkField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          this.$i18n.t(
            'field.type-of-work.sunscreens.options.mounting-installing',
          ),
          this.$i18n.t(
            'field.type-of-work.sunscreens.options.mounting-installing',
          ),
          this.$i18n.t(
            'field.type-of-work.sunscreens.options.mounting-installing',
          ),
        ),
        new Selectable(
          this.$i18n.t('field.type-of-work.sunscreens.options.repair'),
          this.$i18n.t('field.type-of-work.sunscreens.options.repair'),
          this.$i18n.t('field.type-of-work.sunscreens.options.repair'),
        ),
      ],
    }
  },
}
</script>

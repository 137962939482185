<template>
  <AmountField
    :default-choice-selected="false"
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import AmountField from 'chimera/all/components/form/fields/amount/AmountField'

export const field = 'amount'
export default {
  name: 'AmountFormPart',

  components: {
    AmountField,
  },
  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable('1', '1', '1'),
        new Selectable('2', '2', '2'),
        new Selectable('3', '3', '3'),
        new Selectable('Meer dan 4', 'Meer dan 4', 'Meer dan 4'),
      ],
    }
  },
}
</script>

<template>
  <FormModal show-back-btn :title="headTitle">
    <template #body>
      <TypeOfWorkFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfWorkFormStep from '~/components/form/steps/typeOfWork/TypeOfWorkFormStep'

export default {
  components: {
    TypeOfWorkFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wat moet er gedaan worden?',
      headDescription: 'Wat moet er gedaan worden?',
      path: '/offertes-aanvragen/type-werk',
      progressValue: 22,
      checkoutStep: 2,
    }
  },
}
</script>

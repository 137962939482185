<template>
  <ControlMethodField
    :choices="choices"
    :multiple="false"
    :default-choice-selected="false"
    v-on="$listeners"
  />
</template>

<script>
import ControlMethodField from 'chimera/all/components/form/fields/controlMethod/ControlMethodField.vue'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ControlMethodFormPart',

  components: {
    ControlMethodField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          this.$i18n.t('field.control-method.options.manual'),
          this.$i18n.t('field.control-method.options.manual'),
          this.$i18n.t('field.control-method.options.manual'),
        ),
        new Selectable(
          this.$i18n.t('field.control-method.options.electric'),
          this.$i18n.t('field.control-method.options.electric'),
          this.$i18n.t('field.control-method.options.electric'),
        ),
        new Selectable(
          this.$i18n.t('field.control-method.options.automatic'),
          this.$i18n.t('field.control-method.options.automatic'),
          this.$i18n.t('field.control-method.options.automatic'),
        ),
        new Selectable(
          this.$i18n.t('field.control-method.options.advise'),
          this.$i18n.t('field.control-method.options.advise'),
          this.$i18n.t('field.control-method.options.advise'),
        ),
      ],
    }
  },
}
</script>
